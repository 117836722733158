<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex lg12>
        <v-card>
          <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
          <v-data-table 
            :headers="headers"
            :items="items"
            :options.sync="pagination"
            :server-items-length="total_rows"
            :footer-props="items_per_page"
            :must-sort="true"
            :loading="loading"
            :mobile-breakpoint="100"
          >
            <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.ID }}</td>
                <td>{{ item.OrderID ? (item.OrderType == 1 ? 'MD' : 'KG') +item.OrderID : '' }}</td>
                <td class="text-end">{{ item.Amount | currency}}</td>
                <td>{{ item.CustomerName }}</td>
                <td v-if="item.Status != payment_type_enum.PhiKhac" v-bind:style="{ 'color': get_type_color(item.Status, payment_type)}">{{ item.Status | display_value(payment_type) }}</td>
                <td v-if="item.Status == payment_type_enum.PhiKhac">{{ item.FeeName }}</td>
                <td>{{ item.CreatedDate | display_date }}</td>
                <td>{{ item.CreatedBy }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "../../configs";
import Filtering from "@/components/Filtering";
import _ from "lodash";

export default {
  components: {
    "hpo-filter": Filtering
  },
  data() {
    let dataType = configs.DATA_TYPE;
    return {
      search: "",
      headers: [
        {
          text: "ID",
          value: "ID",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: "Mã đơn hàng",
          value: "OrderID",
          quickSearch: true,
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: "Số tiền",
          value: "Amount",
          align: "end",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: "User Name",
          value: "CustomerName",
          quickSearch: true,
          filterable: true,
          dataType: dataType["String"]
        },
        {
          text: "Loại giao dịch",
          value: "Status",
          filterable: true,
          dataType: dataType["Enum"],
          values: _.filter(configs.PAYMENT_STATUS_LIST, function(o) { return (o.value == 9 || o.value == 10);})
        },
        {
          text: "Ngày tạo",
          value: "CreatedDate",
          filterable: true,
          dataType: dataType["Date"]
        },
        {
          text: "Người tạo",
          value: "CreatedBy",
          quickSearch: true,
          filterable: true,
          dataType: dataType["String"]
        },
      ],
      payment_type: configs.PAYMENT_STATUS_LIST,
      payment_type_enum: configs.PAYMENT_STATUS,
      pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
      items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
      viewName: "payment_history_list",      
      filterConditions: [],
      quickFilterConditions: []
    };
  },
  watch: {
    pagination: {
      handler: function(val, oldVal) {
        let filterConditions = this.$store.state.commons.filterConditions;
        this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
        this.filter_data();
      },
      deep: true
    }
  },
  computed: mapState({
    items: state => state.paymentHistory.all.data,
    total_rows: state => state.paymentHistory.all.total,
    loading: state => state.paymentHistory.loading,
  }),
  methods: {
    filter_data: function() {
      var param = {
        pagination: this.pagination,
        search_term: this.search,
        filter: this.filterConditions,
        quickFilter: this.quickFilterConditions
      };
      param.filter.push({
        column: "Status",
        value: [2, 12],
        condition: "not_in"
      })
      this.$store.dispatch("paymentHistory/getPaymentHistoryList", param);
    },
    gotoDetail: function(id) {
      this.$router.push({
        name: "PaymentHistoryDetail",
        params: { Pid: id, title: `Lịch sử thanh toán (#${id})` }
      });
    },
    applyFilter(filterConditions) {
      let sortBy = this.pagination.sortBy
      let sortDesc = this.pagination.sortDesc
      this.pagination = configs.PAGINATION;
      this.pagination.sortBy = sortBy;
      this.pagination.sortDesc = sortDesc;
      this.filterConditions = filterConditions;
      this.filter_data();
    },
    quickFilter(filterConditions) {
      this.quickFilterConditions = filterConditions;
      this.filter_data();
    },
    get_type_color(val, enums) {
      return this.$options.filters.display_color(val, enums);
    },
  },
  mounted() {
    this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
  }
};
</script>